// extracted by mini-css-extract-plugin
export var Back = "ecodiag-module--Back--65ca4";
export var Carousel = "ecodiag-module--Carousel--1fc93";
export var CarouselImage = "ecodiag-module--CarouselImage--0e1e6";
export var CarouselItem = "ecodiag-module--CarouselItem--73090";
export var ColImage = "ecodiag-module--ColImage--ab853";
export var Container = "ecodiag-module--Container--669ea";
export var GatsbyImage = "ecodiag-module--GatsbyImage--33374";
export var Text = "ecodiag-module--Text--29ebd";
export var Titre1 = "ecodiag-module--Titre1--e6a9b";
export var Titre2 = "ecodiag-module--Titre2--013f7";