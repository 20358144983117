import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import * as EcodiagStyles from "../../styles/ecodiag.module.css";
import Seo from "../../components/seo";

export default function Ecodiag({ data }) {
  // console.log(data.projects.nodes[0].frontmatter.thumb.childImageSharp.gatsbyImageData)
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo description="Le diagnostic énergétique. L’analyse rapide de combustion 5 gaz (O2, CO2, CO, HC, NOx) permet de vérifier si le moteur est à l'optimum de ses capacités thermodynamiques." />
      <Container fluid className={EcodiagStyles.Container}>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={EcodiagStyles.Titre1}>
              <Trans i18nKey="ecosysteme.ecodiag.savoirplus.h.1">
                <sup></sup>
              </Trans>
            </h1>

            <h2 className={EcodiagStyles.Titre2}>
              {t("ecosysteme.ecodiag.savoirplus.h.2")}
            </h2>
            <p className={EcodiagStyles.Text}>
              <Trans i18nKey="ecosysteme.ecodiag.savoirplus.p.1">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col
            xs={12}
            lg={{ span: 4, offset: 1 }}
            className={EcodiagStyles.ColImage}
          >
            <StaticImage
              className={EcodiagStyles.GatsbyImage}
              src="../../images/savoirplus/ecodiag/motor.png"
              alt="moteur"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="fullWidth"
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 3, offset: 1, order: 1 }}
          >
            <StaticImage
              className={EcodiagStyles.GatsbyImage}
              src="../../images/gaz.png"
              alt="gaz"
              placeholder="BLURRED"
              formats={["AUTO", "WEBP"]}
              layout="constrained"
            />
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, offset: 1, order: 2 }}
          >
            <h2 className={EcodiagStyles.Titre2}>
              {t("ecosysteme.ecodiag.savoirplus.h.3")}
            </h2>
            <p className={EcodiagStyles.Text}>
              <Trans i18nKey="ecosysteme.ecodiag.savoirplus.p.2">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 1 }}>
            <h1 className={EcodiagStyles.Titre1}>
              <Trans i18nKey="ecosysteme.ecodiag.savoirplus.h.4">
                <sup></sup>
              </Trans>
            </h1>

            <h2 className={EcodiagStyles.Titre2}>
              {t("ecosysteme.ecodiag.savoirplus.h.5")}
            </h2>
            <p className={EcodiagStyles.Text}>
              <Trans i18nKey="ecosysteme.ecodiag.savoirplus.p.3">
                a<span>b</span>
              </Trans>
            </p>
          </Col>
          <Col xs={12} lg={{ span: 4, offset: 1 }}>
            <Carousel className={EcodiagStyles.Carousel} controls={false}>
              <Carousel.Item className={EcodiagStyles.CarouselItem}>
                <Container fluid className={EcodiagStyles.Back}>
                  <h2>{t("ecosysteme.ecodiag.savoirplus.imagetext.1")}</h2>
                  <p>{t("ecosysteme.ecodiag.savoirplus.imagetext2.1")}</p>
                </Container>
                <StaticImage
                  className={EcodiagStyles.CarouselImage}
                  src={"../../images/savoirplus/ecodiag/moustache.png"}
                  alt="moustache 1"
                />
              </Carousel.Item>
              <Carousel.Item className={EcodiagStyles.CarouselItem}>
                <Container fluid className={EcodiagStyles.Back}>
                  <h2>
                    <Trans>
                      {t("ecosysteme.ecodiag.savoirplus.imagetext.2")}
                    </Trans>
                  </h2>
                </Container>
                <StaticImage
                  className={EcodiagStyles.CarouselImage}
                  src={"../../images/savoirplus/ecodiag/moustache2.png"}
                  alt="moustache 2"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

// export page query
export const query = graphql`
  query Ecodiag($language: String!) {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
